.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: -15px;
}

.item {
  padding: 15px;
}

.root {
  height: 100vh;
  width: 100vw;
  max-height: fit-content;
  max-width: fit-content;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sales {
  width: 100%;
  height: 100%;
  padding: 15px;
}

.content {
  padding: 50px;
}

.body {
  width: 100%;
  padding: 50px;
}